import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { defineMessages, injectIntl } from "react-intl";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/pro-regular-svg-icons";

// services
import { userLoggedin } from '../../services/user';
import { messagecenterGetMessages, messagecenterGetSingleMessage, messagecenterSetMessageUnread, checkMessageCenterMaintenance } from '../../services/messagecenter';

// components
import MessageCard from './components/MessageCard';
import MessageCardSkeleton from './components/MessageCardSkeleton';
import AlertDialog from '../../components/AlertDialog';
import MessageContent from './components/MessageContent';

// translations
export const messages = defineMessages({
    messageCenter: {
        id: "Viestikeskus",
        defaultMessage: 'Viestikeskus'
    },
    title: {
        id: "Viestit",
        defaultMessage: 'Viestit'
    },
    noMessages: {
        id: "Sinulla ei ole yhtään viestiä.",
        defaultMessage: 'Sinulla ei ole yhtään viestiä.'
    },
    authError: {
        id: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tunnistautuminen epäonnistui. Ole hyvä ja yritä uudelleen."
    },
    authErrorShort: {
        id: "Tunnistautuminen epäonnistui",
        defaultMessage: "Tunnistautuminen epäonnistui"
    },
    authRequired: {
        id: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa.",
        defaultMessage: "Sinun täytyy tunnistautua ennen kuin voit käyttää MyRopoa."
    },
    disabledInEnvironment: {
        id: "Viestikeskus ei ole käytössä tässä ympäristössä.",
        defaultMessage: "Viestikeskus ei ole käytössä tässä ympäristössä."
    },
    errorOccurredTryAgain: {
        id: "Tapahtui virhe. Ole hyvä ja yritä uudelleen.",
        defaultMessage: "Tapahtui virhe. Ole hyvä ja yritä uudelleen."
    },
    backToMessages: {
        id: "Palaa saapuneisiin viesteihin",
        defaultMessage: "Palaa saapuneisiin viesteihin"
    },
    underMaintenance: {
        id: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen.",
        defaultMessage: "Pahoittelemme, viestikeskus on tilapäisesti poissa käytöstä huollon vuoksi. Yritäthän myöhemmin uudelleen."
    }
});


const MessageCenter = (props) => {
    const lng = props.intl.formatMessage;
    const location = props.location; 
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
    const [singleMessage, setSingleMessage] = useState(allMessages[0] ?? {});
    const [showInModal, setShowInModal] = useState(false);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0); // first message as the active card by default
    const skeletonCount = 5;
    const messageRefs = useRef([]); 
    const hasHandledMessageCard = useRef(false);

    // check message center rights
    let messageCenter = false;

    if (typeof process.env.REACT_APP_MESSAGECENTER !== 'undefined' && process.env.REACT_APP_MESSAGECENTER === '1') {
        messageCenter = true;
    }


    useEffect(() => {
        // do not allow access to message center page not allowed in the environment or user is not logged in
        if (!userLoggedin()) {
            const alert = { message: lng(messages.authRequired), type: 'primary' };
            props.history.push({
                pathname: "/",
                alert
            });

        } else if (!messageCenter) {
            // redirect to jobsearch, if message center is not enabled in the environment
            const alert = { message: lng(messages.disabledInEnvironment), type: 'primary' };
            props.history.push({
                pathname: "/jobsearch",
                alert
            });

        } else {
            
            // message center is enabled and user is logged in, get all user's messages
            setIsAuthenticated(true);
            checkMaintenance();
        }

    }, []);

    useEffect(() => {
        // Get the messageId from the query parameters
        const queryParams = new URLSearchParams(location.search);
        const messageId = queryParams.get('messageId');

        if (messageId && !hasHandledMessageCard.current) {
            // Find the message with the given messageId
            const messageIndex = allMessages.findIndex(msg => msg.id === messageId);
            if (messageIndex !== -1) {
                setActiveIndex(messageIndex); // Set the active index based on messageId
                setSingleMessage(allMessages[messageIndex]);
                setShowInModal(true);

                if (allMessages[messageIndex].current_status.unread == 1) {
                    messagecenterSetMessageUnread(props.updateUnreadMessagesNotification, messageId).then(() => {
                        // Update the message's isUnread value
                        const updatedMessages = allMessages.map((message, index) => {
                            if (index === messageIndex) {
                                return { ...message, current_status: { ...message.current_status, unread: 0 } };
                            }
                            return message;
                        });
                        setAllMessages(updatedMessages);
                    });
                }

                // Scroll to the active message card
                if (messageRefs.current[messageIndex]) {
                    messageRefs.current[messageIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                hasHandledMessageCard.current = true; //trigger processing only once when the messageId is in the query parameters
            }
        }
    }, [location.search, allMessages]);
    
    /**
     * Check if maintenance is ongoing.
     */
    function checkMaintenance() {
        const maintenance = async () => {
            checkMessageCenterMaintenance()
                .then(function (response) {
                    if (response) {
                        showAlerts({
                            message: lng(messages.underMaintenance),
                            type: 'warning'
                        });
                    } else {
                        // message center is enabled and user is logged in, get all user's messages
                        getAllUserMessages();
                    }
                }).catch(function (error) {
                if (error?.response?.status === 401) {
                    // session expired, log user out
                    const alert = {message: lng(messages.authError), type: 'danger'};
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            })
        };
        maintenance();
    }
    
    /**
     * Check if messages can be loaded from the session storage
     * or if they need to be fetched from the API.
     *
     * Show messages in the listing.
     */
    function getAllUserMessages() {
        clearAlerts();
        setLoadingTickets(true);

        const getMessages = async () => {

            try {
                // Fetch all stored messages
                const allStoredMessages = await messagecenterGetMessages();
                
                // Check if user has messages and set them
                checkLenghtAndSetMessages(allStoredMessages);
                
                // Set first message to be shown by default
                setSingleMessage(allStoredMessages[0] ?? {});
            } catch (error) {
                if (error?.response?.status === 401) {
                    // session expired, log user out and show error message
                    const alert = { message: lng(messages.authError), type: 'danger' };
                    props.history.push({
                        pathname: "/logout",
                        alert
                    });
                } else {
                    // show error message
                    showAlerts({
                        message: lng(messages.errorOccurredTryAgain),
                        type: 'danger'
                    });
                }
            } finally {
                // Toggle skeleton loading off
                setLoadingTickets(false);
            }
        };
        getMessages();
    }

    /**
     * Check passed message array and show them in the listing.
     * If messages are empty, show notification in alertDialog.
     *
     * @param {*} allMessages array of messages
     */
    function checkLenghtAndSetMessages(allMessages) {
        // if user has no messages, show notification in alertDialog
        if (allMessages.length === 0) {
            showAlerts({
                message: lng(messages.noMessages),
                type: 'primary'
            });
        } else {
            // sort messages so that the newest createad or the latest message to have a new comment is first
            allMessages.sort((a, b) => {
                let aLatest = new Date(a.current_status.status_date);
                let bLatest = new Date(b.current_status.status_date);

                // check if message has comments
                if (a.comments.length > 0) {
                    a.comments.forEach(comment => {
                        aLatest = new Date(comment.sent_date);
                    })
                }

                if (b.comments.length > 0) {
                    b.comments.forEach(comment => {
                        bLatest = new Date(comment.sent_date);
                    })
                }

                return bLatest - aLatest;
            });
        }

        hasHandledMessageCard.current = false;

        // set messages
        setAllMessages(allMessages);
    }

    /**
     * Set alert message and type, and show alertDialog.
     *
     * @param {*} alert
     */
    function showAlerts(alert) {
        setAlertMessage(alert.message);
        setAlertType(alert.type);

        setShowAlert(true);
    }

    /**
     * Clear error messages and hide alertDialog.
     */
    function clearAlerts() {
        setAlertMessage('');
        setAlertType('');

        setShowAlert(false);
    }

    /**
     * Handle messagecard clicks.
     *
     * @param {*} chosen
     */
    function handleMessagecard(chosen) {
        setActiveIndex(chosen);
        // Extract the message ID from the chosen message using the index
        const messageId = allMessages[chosen].id;
    
        if (allMessages[chosen].current_status.unread === 1) {
            messagecenterSetMessageUnread(props.updateUnreadMessagesNotification, messageId).then(() => {
                // Update the message's isUnread value
                const updatedMessages = allMessages.map((message, index) => {
                    if (index === chosen) {
                        return { ...message, current_status: { ...message.current_status, unread: 0 } };
                    }
                    return message;
                });
                setAllMessages(updatedMessages);
            });
        }    
        setSingleMessage(messagecenterGetSingleMessage(allMessages[chosen].id) ?? {});
        setShowInModal(true);      
    }

    /**
     * Handle modal hide in mobile views.
     */
    function handleHideModal() {
        setShowInModal(false);
    }

    return (
        <>
            {isAuthenticated && (
                <div className="content flex-fill">
                    <Helmet id='job-helmet'>
                        <title>{lng(messages.messageCenter)} | MyRopo</title>
                    </Helmet>
                    <Container id='message-center' fluid className='p-0 d-flex h-100'>
                        <Container className="p-4 pb-0">
                            <Row className='mb-3 g-5 pb-2 pt-0 pt-xl-3'>
                                <Col className='welcome-message'>
                                    <h1 className='ps-1 pe-1'>{lng(messages.title)}</h1>
                                </Col>
                            </Row>
                            <Row className='mb-3 g-5 pb-2 pt-0 pt-xl-3'>
                                {showAlert && (
                                    <Col >
                                        <AlertDialog
                                            type={alertType}
                                            message={alertMessage}
                                        />
                                    </Col>
                                )}
                                {loadingTickets ? (
                                    <>
                                        <Col xs={12} md={5} lg={4}>
                                            <MessageCardSkeleton count={skeletonCount} />
                                        </Col>
                                        <Col xs={12} md={8} className="text-center">
                                            <MessageCardSkeleton count={1} />
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        {allMessages.length > 0 && (
                                            <>
                                                <Col xs={12} sm={4} md={5} lg={4} className='message-container'>
                                                    <div className='scrollable-content'>
                                                        {allMessages.map((message, index) => {
                                                            let nextIndex = index < allMessages.length - 1 ? index + 1 : '';

                                                        return (
                                                            <div key={'message-' + message.id} ref={el => messageRefs.current[index] = el}>
                                                                <button id={"message-" + message.id} className="btn messagecard mb-2" onClick={() => handleMessagecard(index)} >
                                                                    <MessageCard
                                                                        cardIndex={index}
                                                                        id={message.id}
                                                                        status={message.current_status.status}
                                                                        summary={message.summary}
                                                                        invoiceNumber={message.jobid}
                                                                        creditor={'[Creditor name here]'} // TODO: add creditor name when available
                                                                        activeIndex={activeIndex}
                                                                        isUnread={message.current_status.unread}
                                                                        {...props}
                                                                    />
                                                                </button>

                                                                    {/* do not show spacers before/after the chosen card*/}
                                                                    <hr className={'mb-2 ' + (activeIndex === index || activeIndex === nextIndex ? 'hide' : '') + " d-none d-sm-block"} />
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={8} md={7} lg={8} className="message-container d-none d-sm-block">
                                                    <MessageContent
                                                        id={singleMessage.id ?? ''}
                                                        summary={singleMessage.summary ?? ''}
                                                        description={singleMessage.description ?? ''}
                                                        date={singleMessage.current_status?.status_date ?? ''}
                                                        comments={singleMessage.comments ?? []}
                                                        attachments={singleMessage.attachments ?? []}
                                                    />
                                                </Col>

                                                {/* Use modal to show message content in mobile */}
                                                <div className='message-mobile'>
                                                    <Modal className='d-block d-sm-none pb-0' show={showInModal} onHide={handleHideModal} backdrop={false} contentClassName='mobile-message-content'>
                                                        <Modal.Header className='fixed-element-top ps-4 pb-3' >
                                                            <Button className="modal-back" onClick={handleHideModal}>
                                                                <FontAwesomeIcon icon={far.faArrowLeft} /> {lng(messages.backToMessages)}
                                                            </Button>
                                                        </Modal.Header>
                                                        <Modal.Body className='p-0'>
                                                            <MessageContent
                                                                id={singleMessage.id ?? ''}
                                                                summary={singleMessage.summary ?? ''}
                                                                description={singleMessage.description ?? ''}
                                                                date={singleMessage.current_status?.status_date ?? ''}
                                                                comments={singleMessage.comments ?? []}
                                                                attachments={singleMessage.attachments ?? []}
                                                            />
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </>
                                        )}
                                    </>
                                )
                                }
                            </Row>
                        </Container>
                    </Container>
                </div >
            )}
        </>
    );
}


MessageCenter.propTypes = {
    'intl': PropTypes.object.isRequired,
    'history': PropTypes.object,
    'location': PropTypes.object,
    'updateUnreadMessagesNotification': PropTypes.func,
};

export default injectIntl(MessageCenter);