import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userIsAuthorized, userGetCustomerType, CUSTOMERTYPE } from "../../services/user";
import { role } from "../../services/helpers/role";
import ProfileMenu from "../ProfileMenu/ProfileMenu";


const TopMenu = (props) => {
    const userAuthorized = userIsAuthorized(role.authenticated);
    const [showProfileMenuContents, setShowProfileMenuContents] = useState(false);
    const [showTopMenu, setShowTopMenu] = useState(false);
    const unreadMessagesNotification = props.unreadMessagesNotification > 0 ? '' : 'd-none';

    useEffect(() => {
        // check if topmenu should be visible
        if (props.showTopMenu) {
            setShowTopMenu(true);
        } else {
            setShowTopMenu(false);
        }

    }, [props.showTopMenu]);

    
    /**
     * Toggle visibility of profile menu contents.
     */
    function toggleProfileMenu() {
        setShowProfileMenuContents(!showProfileMenuContents);
    }

    return (
        <>
            {showTopMenu && (
                <Container fluid className={"g-0" + (showProfileMenuContents ? " profilemenu-open" : "")}>
                    <div className={userAuthorized ? "top-menu-position pt-0" : "position-relative"}>
                        <Container className="top-menu flex-fill h-100" role="menubar" >
                            <Row className="mt-2 mt-xl-0 pt-2 pt-xl-0" aria-label="Top">
                                {userAuthorized && (
                                    <Col xs={4} className="d-xl-none d-block">
                                        <button aria-label='Sidemenu bars' onClick={props.sideMenuToggle} className="sidemenutoggle" aria-haspopup="true" aria-controls="menu">
                                            <FontAwesomeIcon icon={far.faBars} />
                                            <div id="messageCenterUnreadIndicator" className={"messageCenterUnread icon-need-actions "+unreadMessagesNotification}></div>
                                        </button>
                                    </Col>
                                )}
                                {/* Hide logo, if user is in job page, because browse button is shown in it's place */}
                                <Col xs={{ span: 4, offset: userAuthorized ? 0 : 4 }} className="justify-content-center text-center d-xl-none d-block topbar-brand">
                                    {window.location.hash !== '#/job' && (
                                        <NavLink to={userAuthorized ? "/jobsearch" : "/"}>
                                            <svg width="119" height="32" viewBox="0 0 119 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M63.5214 18.4203C63.5214 14.5473 61.2872 12.9415 57.9668 13.3823C57.9668 13.3823 64.4834 12.5006 64.4834 6.83283C64.4834 1.16504 61.6906 0 55.5774 0H46.1438V24.6864H49.1228V18.3573C49.1228 17.8535 49.3401 17.4127 49.6814 17.0663C50.9537 15.9013 53.0949 14.7677 55.4843 14.1065C58.5874 13.2563 60.3562 14.4529 60.3562 18.1684C60.3562 21.884 61.0389 24.3715 61.2251 24.6549H64.4214C64.3283 24.403 64.2352 24.0566 64.111 23.6158C63.9869 23.1435 63.4594 21.4117 63.4594 18.3888L63.5214 18.4203ZM54.5844 11.7449C52.6294 12.2802 50.6744 12.91 49.1849 14.138V2.61348H55.4533C56.8497 2.61348 61.4113 2.07819 61.4113 6.20308C61.4113 10.328 59.8908 9.91863 57.8427 10.7058C56.8497 11.0837 55.7325 11.3986 54.5844 11.7134V11.7449Z" fill="black" />
                                                <path d="M92.5358 6.67542C88.1604 6.67542 86.6088 10.0446 86.5778 10.1076V7.17922H83.5677V30.795H86.5778V21.758C86.857 22.2303 88.2535 25.1902 92.5358 25.1902C96.8182 25.1902 100.356 21.8525 100.356 15.9643C100.356 10.0761 96.694 6.7069 92.5358 6.7069V6.67542ZM91.729 22.4193C87.2915 22.4193 86.3916 17.948 86.3916 15.9328C86.3916 13.9176 87.2915 9.41485 92.0083 9.41485C96.7251 9.41485 97.1285 12.5636 97.1285 15.9328C97.1285 19.302 94.8632 22.4193 91.729 22.4193Z" fill="black" />
                                                <path d="M71.7137 6.80141C67.059 7.77753 64.08 12.6581 65.0419 17.6647C66.0039 22.7027 70.5966 25.9774 75.2513 24.9698C79.906 23.9937 82.885 19.1131 81.9231 14.1066C80.9611 9.06852 76.3684 5.7938 71.7137 6.80141ZM74.8789 23.0491C72.024 23.6473 69.076 20.9394 68.3002 16.9719C67.5245 13.0045 69.2312 9.32042 72.0861 8.72216C74.941 8.12389 77.889 10.8318 78.6648 14.7993C79.4405 18.7667 77.7338 22.4508 74.8789 23.0491Z" fill="black" />
                                                <path d="M108.61 6.83277C103.955 7.80889 100.976 12.6895 101.938 17.696C102.9 22.7341 107.493 26.0088 112.148 25.0012C116.802 24.0251 119.782 19.1445 118.82 14.1379C117.858 9.13138 113.265 5.82516 108.61 6.83277ZM111.775 23.1119C108.92 23.7102 105.972 21.0022 105.197 17.0348C104.421 13.0673 106.128 9.38328 108.983 8.78501C111.837 8.18675 114.785 10.8947 115.561 14.8621C116.337 18.8296 114.63 22.5137 111.775 23.1119Z" fill="black" />
                                                <g opacity="0.5">
                                                    <path d="M12.4746 21.1913L5.15122 0.125977H0V24.7179H3.32037L3.13418 3.9045L10.6128 24.7179H14.2434L21.7841 3.9045L21.5669 24.7179H24.9493V0.125977H19.767L12.4746 21.1913Z" fill="black" />
                                                    <path d="M35.8413 20.5615L30.1005 7.21069H26.625L34.0415 24.277L33.3278 26.0404C31.9314 29.0632 29.6971 29.4725 27.618 28.9372V31.7396C31.6521 32.7472 34.7863 30.8265 36.7413 26.0404L44.4991 7.21069H41.1788L35.8724 20.5615H35.8413Z" fill="black" />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    )}
                                </Col>
                                <Col xs={4} xl={12} className="d-inline-flex justify-content-end pe-3">
                                    <div className="profilemenu">
                                        <button aria-label='User settings' onClick={() => toggleProfileMenu()} title={'User settings'}>
                                            {userGetCustomerType() === CUSTOMERTYPE.COMPANY ? (
                                                <FontAwesomeIcon icon={far.faUsers} />
                                            ) : (
                                                <FontAwesomeIcon icon={far.faUser} />
                                            )}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <ProfileMenu
                                show={showProfileMenuContents}
                                hide={toggleProfileMenu}
                                userAuthorized={userAuthorized}
                                {...props}
                            />
                        </Container>
                    </div>
                </Container>
            )}
        </>
    );
};

TopMenu.propTypes = {
    'sideMenuToggle': PropTypes.func.isRequired,
    'showTopMenu': PropTypes.bool.isRequired,
    'unreadMessagesNotification': PropTypes.number.isRequired,
    'updateUnreadMessagesNotification': PropTypes.func.isRequired,
};

export default withRouter(injectIntl(TopMenu));
