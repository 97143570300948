import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import { messages as jobmessages } from '../../messages';

const BankInformation = (props) => {

    const lng = props.intl.formatMessage;

    // bank information 
    return (
        <>
            {props.job.accounts !== null && (
                <Card className='fullwidth-mobile invoice-details bank-information grey-bottom-border-mobile pt-0 pb-0 pt-sm-4 pb-sm-3'>
                    <Card.Body>
                        <h3>{lng(jobmessages.accountInformation)}</h3>
                        <ul className='list-unstyled'>
                            {props.job.accounts.map((account, i) => {
                                return (
                                    <li key={"account-iban-" + i}>
                                        <p><span className='label'>{ lng(jobmessages.iban) }</span> {account.iban}</p>
                                        <p><span className='label'>{ lng(jobmessages.bic) }</span> {account.bic}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

BankInformation.propTypes = {
    'intl': PropTypes.object.isRequired,
    'job': PropTypes.object,
};

export default BankInformation;