import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import StatusBadge from "../../../components/StatusBadge";
import MessageStatus from "../../../components/MessageStatus";

const MessageCard = (props) => {

    const [summary, setSummary] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [creditor, setCreditor] = useState('');


    useEffect(() => {

        // check if props are defined and set them to state
        if (props.summary !== undefined && props.summary !== '') {
            setSummary(props.summary);
        }
        if (props.invoiceNumber !== undefined && props.invoiceNumber !== '') {
            setInvoiceNumber(props.invoiceNumber);
        }
        if (props.creditor !== undefined && props.creditor !== '') {
            setCreditor(props.creditor);
        }

    });


    return (
        <Card key={'messagecard-' + props.id} className={'fullwidth-mobile messagecard grey-bottom-border-mobile ps-4 pe-4 p-sm-0' + (props.cardIndex === props.activeIndex ? ' chosen ' : '')} role="messagecard">
            <Card.Body>
                <Row>
                    <Col className="mb-2 mb-sm-3">
                        <StatusBadge
                            statusCode={props.statusCode}
                            dueDate={props.dueDate}
                            lng={props.intl.formatMessage}
                        />
                        <span className="me-1" />
                        <MessageStatus
                            originalStatus={props.status}
                            lng={props.intl.lng}
                            {...props}
                        />

                            {props.isUnread ? (
                                <FontAwesomeIcon className="message-center-new-messages text-danger" icon={fas.faCircle}/>
                            ) : null}
                    </Col>
                    <Col xs={12} className="mb-2 mb-md-1">
                        <h5 className="mb-1">{summary}</h5>
                    </Col>
                    <Col xs={12} className="mb-2 mb-md-1">
                        <p className="fs-6 mb-1">{invoiceNumber} <span className="text-muted ms-2"> {creditor}</span></p>
                    </Col>
                </Row>
            </Card.Body>
        </Card >
    );
};


MessageCard.propTypes = {
    'intl': PropTypes.object,
    'id': PropTypes.string,
    'unread': PropTypes.number,
    'status': PropTypes.string,
    'summary': PropTypes.string,
    'invoiceNumber': PropTypes.string,
    'creditor': PropTypes.string,
    'cardIndex': PropTypes.number,
    'activeIndex': PropTypes.number,
    'isUnread': PropTypes.number,
    'statusCode': PropTypes.number,
    'dueDate': PropTypes.string
};

export default injectIntl(MessageCard);